import React, { useState, useEffect } from "react";

import { LinearIndeterminate, FilterSelector } from "../index";
import { BaseUrl, Coockies_name } from "../../constants";
import { useCookies } from "react-cookie";
import VisitesTables from "../Utils/VisitesTables";
import FilterSelectorSearch from "../Utils/FilterSelectorSearch";

function Visites() {
  const [cookies] = useCookies([Coockies_name]);
  const [ListeVisites, setListeVisites] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [partnerFilter, setPartnerFilter] = useState(0);
  const [clientFilter, setClientFilter] = useState(0);
  const [partners, setPartners] = useState([]); // State for partner list
  const [clients, setClients] = useState([]); // State for client list

  // Fetch partners and clients when the component mounts
  useEffect(() => {
    fetchPartners();
    fetchClients();
    handleRequest();
  }, []);

  // Fetch partners from the API
  const fetchPartners = async () => {
    try {
      const req = await fetch(`${BaseUrl}/admin/get_partners`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.accesToken}`,
        },
        body: JSON.stringify({
          page_number: 0,
          records_per_page: 999999,
          search_filter: "",
        }),
      });
      if (req.ok) {
        const response = await req.json();
        setPartners(response.partners);
      }
    } catch (err) {
      console.error("Error fetching partners:", err);
    }
  };

  // Fetch clients from the API
  const fetchClients = async () => {
    try {
      const req = await fetch(`${BaseUrl}/clients/all_filtered`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.accesToken}`,
        },
        body: JSON.stringify({
          page_number: 0,
          records_per_page: 999999,
          search_filter: "",
        }),
      });
      if (req.ok) {
        const response = await req.json();
        setClients(response.clients); 
      }
    } catch (err) {
      console.error("Error fetching clients:", err);
    }
  };

  // Fetch the list of visits
  const handleRequest = async () => {
    setLoading(true);
    try {
      const req = await fetch(`${BaseUrl}/partners/history-dashboard`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.accesToken}`,
        },
      });
      if (req.ok) {
        const response = await req.json();
        setListeVisites(response);
        setData(response); // Initialize data with the full list
      }
    } catch (err) {
      console.error("Error fetching visits:", err);
    }
    setLoading(false);
  };

  // Apply filters based on partner_id and client_id
  useEffect(() => {
    let filteredData = ListeVisites;
    if (partnerFilter !== 0) {
      filteredData = filteredData.filter(
        (item) => item.partner_id === partnerFilter
      );
    }
    if (clientFilter !== 0) {
      filteredData = filteredData.filter(
        (item) => item.client_id === clientFilter
      );
    }
    setData(filteredData);
  }, [partnerFilter, clientFilter, ListeVisites]);

  return (
    <div className="p-5 my-10">
      <div className="flex flex-col items-start justify-start">
        <h1 className="text-[20px] font-black leading-9 text-gray-800">
          Historique des visites
        </h1>
        <p className="text-[16px] font-normal leading-9 text-gray-500">
          Historique de toutes les visites effectuées par nos clients chez nos partenaires
        </p>
      </div>
      <div className="flex ld:flex-row flex-col w-full mt-[10px] lg:gap-5 gap-0 justify-center items-center">
        <div className="flex flex-row w-full mt-[10px] gap-5 justify-start items-center">
          {/* Partner Filter */}
          <FilterSelector
            title={"Partenaire"}
            styles={"h-[95px]"}
            options={[
              { value: 0, name: "Tous" }, // Default option
              ...partners.map((partner) => ({
                value: partner.id,
                name: partner.nome_entreprise,
              })),
            ]}
            setFilter={(value) => setPartnerFilter(Number(value))}
            Filter={partnerFilter}
          />
          {/* Client Filter */}
          <FilterSelectorSearch
            title={"Client"}
            styles={"h-[95px]"}
            options={[
              { value: 0, name: "Tout" }, // Default option
              ...clients.map((client) => ({
                value: client.id,
                name: client.full_name,
              })),
            ]}
            setFilter={(value) => setClientFilter(Number(value))}
            Filter={clientFilter}
          />
        </div>
      </div>
      {loading ? (
        <LinearIndeterminate />
      ) : (
        <VisitesTables Data={data} />
      )}
    </div>
  );
}

export default Visites;