import React, { useState, useEffect, useRef } from "react";

const FilterSelectorSearch = ({
  title = "",
  options = [],
  styles,
  setFilter = () => {},
  Filter,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);

  // Filter options based on search term
  useEffect(() => {
    const filtered = options.filter(option => 
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOptions(filtered);
  }, [searchTerm, options]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle selection
  const handleSelect = (value) => {
    setFilter(value === "Tout" ? "" : value);
    setIsOpen(false);
  };

  // Reset search when dropdown closes
  useEffect(() => {
    if (!isOpen) {
      setSearchTerm("");
    }
  }, [isOpen]);

  return (
    <div className={`w-[250px] ${styles} relative`} ref={dropdownRef}>
      <label
        htmlFor={title}
        className="block font-black mb-2 text-sm text-gray-900"
      >
        {title}
      </label>
      
      {/* Custom select with search */}
      <div className="relative">
        <div
          className="h-[40px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500
          focus:border-blue-500 w-full p-2.5 flex justify-between items-center cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>
            {Filter
              ? options.find(o => o.value === Filter)?.name || ""
              : "Tout"}
          </span>
          <svg
            className={`w-4 h-4 transition-transform ${isOpen ? "rotate-180" : ""}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
        
        {/* Dropdown with search */}
        {isOpen && (
          <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg">
            <div className="p-2">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full p-2 text-sm border border-gray-300 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                onClick={(e) => e.stopPropagation()}
                autoFocus
              />
            </div>
            <div className="max-h-60 overflow-y-auto">
              {filteredOptions.map((item, index) => (
                <div
                  key={index}
                  className={`p-2 hover:bg-gray-100 cursor-pointer ${item.value === Filter ? "bg-blue-50" : ""}`}
                  onClick={() => handleSelect(item.value)}
                >
                  {item.name === "" ? "Tout" : item.name}
                </div>
              ))}
              {filteredOptions.length === 0 && (
                <div className="p-2 text-gray-500 italic">No results found</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterSelectorSearch;