import React from "react";

const DataRow = ({ item, index }) => {
  // Convert scan_time to a readable date format
  const scanDate = new Date(item.scan_time).toLocaleString();
  // Convert created_date to a readable date format
  const createdDate = new Date(item.created_date).toLocaleString();

  return (
    <tr
      className={`text-gray-900 hover:text-[#fff] hover:bg-[#2E5CFF] cursor-pointer ${
        index % 2 === 0 ? "bg-gray-100" : "bg-white"
      } border-b`}
    >
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        {item.partner_name}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        {item.client_name}
      </td>
      {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        {item.statut}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        {item.product}
      </td> */}
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        {scanDate}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        {createdDate}
      </td>
    </tr>
  );
};

function VisitesTables({ Data }) {
  return (
    <div className="flex flex-col border-[1px] my-10 border-gray-200 rounded-lg">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full">
              <thead className="bg-white border-b">
                <tr>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Partenaire
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Client
                  </th>
                  {/* <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Statut
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Produit
                  </th> */}
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Scan Time
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                  >
                    Created Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {Data.map((item, index) => (
                  <DataRow key={index} item={item} index={index} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisitesTables;